import template from './template';
import cardTemplate from './card-template';

const News = {
    generate: (data: { image: string, category: string, title: string, subtitle: string }[]) => {
        const content: string[] = [];
        data.forEach(item => {
            const card = cardTemplate;
            content.push(
                card.replaceAll('%IMAGE_URL%', item.image)
                    .replaceAll('%TITLE%', item.title)
                    .replaceAll('%CATEGORY%', item.category)
                    .replaceAll('%SUBTITLE%', item.subtitle)
            );
        });

        return template.replace('%NEWS%', content.join(''));
    }
};

export default News;