const template = `<li>
      <button class="lh-news-card lhm--link" role="link" tabindex="0" data-navigate-href="#" data-navigate-target="_self">
        <img src="%IMAGE_URL%" alt="%TITLE%" />
        <p class="lht--topic">%CATEGORY%</p>
        <h3>%TITLE%</h3>
        <p class="lht--abstract">%SUBTITLE%</p>
      </button>
    </li>
`;

export default template;