const template = `<section class="lh-content-block lht--news">
  <header>
    <h1>Latest News</h1>
  </header>
  <div class="lhp-main">
    <div class="lh-card-list">
      <ul>
        %NEWS%
      </ul>
    </div>
  </div>
</section>
`;

export default template;