import template from './template';

const Paragraph = {
    generate: (data: any) => {
        const link = data.link ? `<a class="lh-forward-link" href="${data.link}">Read more</a>` : ''

        return template.replace('%TITLE%', data.title)
            .replace('%TEXT%', data.text)
            .replace('%LINK%', link);
    }
};

export default Paragraph;