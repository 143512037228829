const template = `<section class="lh-content-block lht--experts">
  <header>
    <h1>%TITLE%</h1>
  </header>
  <div class="lhp-main">
    <div class="lh-carousel lhv--4">
      <button class="lhp-previous-button" data-action="previous"><span>Previous</span></button>
      <ul data-role="carousel-items">
        %EXPERTS%
      </ul>
      <button class="lhp-next-button" data-action="next"><span>Next</span></button>
    </div>
    <div class="lh-card lhv--highlighted">
      <header>
        <h2>%SUBTITLE%</h2>
      </header>
      <div class="lhp-main">
        <p>%DESCRIPTION%</p>
        <a class="lh-forward-link lht--globe" href="#">See all experts</a>
      </div>
    </div>
  </div>
</section>
`;

export default template;
