import demoData from './demo-data.json';
import Page from './templates/page';
import Parallax from './templates/parallax';
import Paragraph from './templates/paragraph';
import News from './templates/news';
import Experts from './templates/experts';

const Generator = () => {
    let w: Window | null = null;

    const generate = (blocks: { name: string, key: string }[]) => {
        const content: string[] = [];
        const prop = (obj: { [index: string]: any }, key: string) => obj[key];

        blocks.forEach(block => {
            const key = block.key.split('/');
            switch (key[0]) {
                case "Parallax": {
                    const data = prop(demoData[0].data, key[1]);
                    content.push(Parallax.generate(data));
                    break;
                }
                case "Paragraph": {
                    const data = prop(demoData[1].data, key[1]);
                    content.push(Paragraph.generate(data));
                    break;
                }
                case "Experts": {
                    const data = prop(demoData[2].data, key[1]);
                    content.push(Experts.generate(data));
                    break;
                }
                case "News": {
                    const data = prop(demoData[3].data, key[1]);
                    content.push(News.generate(data));
                    break;
                }
            }
        })

        const winHtml = Page.generate(content.join(''));

        if (w) {
            w.close();
        }

        w = window.open('', '', 'menubar=no,status=no,width=1200,height=' + window.screen.availHeight +
            ',width=' + window.screen.availWidth);

        if (w) {
            w.document.write(winHtml);
        }
    }

    return {
        generate
    };
};

export default Generator;