const template = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">

    <title>LHF Website Prototype DEMO</title>

    <link rel="shortcut icon" href="assets/global/images/favicon.ico" type="image/x-icon"/>
    <link rel="icon" href="assets/global/images/favicon.ico"/>

    <script type="text/javascript" src="js/carouselService.js" async="async"></script>

    <link rel="stylesheet" type="text/css" href="css/template.css"/>
    <link rel="stylesheet" type="text/css" href="css/content-block.css"/>
    <style>
      .lh-base-page-template > footer {
        background: #D9D9D9;
        height: 200px;
      }
    </style>
</head>
<body>
    <div class="lh-base-page-template">
        <header>
            <a href="#" class="lh-logo"><img src="assets/global/images/logo.svg" alt="LafargeHolcim Foundation"></a>
            <nav class="lh-portal-menu">
                <ul>
                    <li class="lhc-awards lhs--selected">
                        <a href="/awards/index.html"><span>Awards</span></a>
                    </li>
                    <li class="lhc-forum">
                        <a href="/forum/index.html"><span>Forum</span></a>
                    </li>
                    <li class="lhc-experts">
                        <a href="/experts/index.html"><span>Experts</span></a>
                    </li>
                    <li class="lhc-projects">
                        <a href="/projects/index.html"><span>Projects</span></a>
                    </li>
                    <li class="lhc-media">
                        <a href="/media/index.html"><span>Media</span></a>
                    </li>
                    <li class="lhc-about">
                        <a href="/about/index.html"><span>About</span></a>
                    </li>
                </ul>
            </nav>
        </header>
        <main>
            %TEMPLATE%
        </main>
        <footer>
        </footer>
    </div>
    <script>
        // for the demo, window.load event is not triggered
        const setCarousel = () => {
            if (document.lh && document.lh.CarouselService) {
                const elements = document.querySelectorAll('.lht--experts .lh-carousel');
                for (let i = 0; i < elements.length; i++)
                document.lh.CarouselService.attach(elements.item(i), 4);
            } else {
                setTimeout(setCarousel, 100)
            }
        }
        setTimeout(setCarousel, 100);
    </script>
</body>
</html>`;

export default template;