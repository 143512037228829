const template = `<li>
          <div class="lh-expert-card">
            <header>
              <img src="%PHOTO%" alt="%NAME%" />
              <h3>%NAME%</h3>
            </header>
            <div class="lhp-main">
              <p class="lht--occupation">%TITLE%</p>
              <p class="lht--location">%LOCATION%</p>
            </div>
          </div>
        </li>
`;

export default template;