import template from './template';
import cardTemplate from './card-template';

const Experts = {
    generate: (data: { title: string, experts: any[], subtitle: string, description: string }) => {
        const content: string[] = [];
        data.experts.forEach(item => {
            const card = cardTemplate;
            content.push(
                card.replaceAll('%PHOTO%', item.photo)
                    .replaceAll('%TITLE%', item.title)
                    .replaceAll('%NAME%', item.name)
                    .replaceAll('%LOCATION%', item.location)
            );
        });

        return template.replace('%TITLE%', data.title)
            .replace('%EXPERTS%', content.join(''))
            .replace('%SUBTITLE%', data.subtitle)
            .replace('%DESCRIPTION%', data.description);
    }
};

export default Experts;