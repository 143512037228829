import React, {useState} from 'react';

import data from './demo-data.json';

import Generator from "./Generator";

const inputStyle = {
    outline: "none",
    padding: "10px",
    backgroundColor: "white",
    border: "solid 1px gray",
    borderRadius: "5px",
    cursor: "pointer"
};

const App = () => {
    const [generator] = useState(Generator());
    const [selectedBlocks, setSelectedBlocks] = useState<{ name: string, key: string }[]>([{
        name: 'Main header',
        key: 'header'
    }]);

    const addBlock = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target && event.target.value) {
            const block = {
                name: event.target.value.replace('/', ': '),
                key: event.target.value
            };

            event.target.value = '';
            setSelectedBlocks([...selectedBlocks, block]);
        }
    }

    const generatePreview = () => {
        generator.generate(selectedBlocks);
    }

    return (
        <div style={{padding: "20px"}}>
            <div>
                {selectedBlocks.length ? 'Selected blocks:' : 'Set blocks from the list below.'}
                <ol>
                    {selectedBlocks.map((value, index) => {
                        return <li key={index}>{value.name}</li>;
                    })}
                </ol>
            </div>
            <div>
                <select onChange={addBlock} style={inputStyle}>
                    <option value="">Select a block to add</option>
                    {data.map((item, index) => {
                        return <optgroup key={index} label={item.name}>
                            {Object.keys(item.data).map((value, index) => (
                                <option key={index} value={item.name + "/" + value}>{value}</option>
                            ))}
                        </optgroup>
                    })}
                </select>
            </div>
            {selectedBlocks.length ? <div style={{marginTop: "10px"}}>
                <button style={{...inputStyle, backgroundColor: "#eaeaea"}}
                        onClick={generatePreview}>Generate preview
                </button>
            </div> : null}

        </div>)
};

export default App;
